<template>
  <v-row>
    <v-col md="3" sm="12">
      <datepicker
          id="start"
          :date.sync="localRange.start"
          :label="$t('missions.fields.start')"
          :max="localRange.end"
          :min="minDate"
      ></datepicker>
    </v-col>
    <v-col md="3" sm="12">
      <datepicker
          id="end"
          :date.sync="localRange.end"
          :label="$t('missions.fields.end')"
          :max="maxDate"
          :min="localRange.start"
      ></datepicker>
    </v-col>
    <v-col md="3" sm="12">
      <countries
          :country.sync="localRange.country"
      ></countries>
    </v-col>
    <v-col md="3" sm="12">
      <v-btn color="primary" block :loading="isLoading" @click="setDateRange">{{ $t('analytics.actions.update') }}</v-btn>
    </v-col>
  </v-row>
</template>

<script>

import Datepicker from "@/components/form/Datepicker";
import clonedeep from "lodash.clonedeep";
import Countries from "@/components/form/Countries";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: "RangePicker",

  components: {Datepicker, Countries},

  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      localRange: {},
      maxDate: moment().subtract('1', 'day').toISOString().substr(0, 10),
    }
  },

  mounted() {
    this.localRange = clonedeep(this.statsRange)
  },

  computed: {
    ...mapGetters({
      statsRange: 'analyticsRange/getStatsRange'
    }),
    minDate () {
      return (this.localRange.end) ? moment(this.localRange.end).subtract('30', 'day').toISOString().substr(0, 10) : moment().subtract('30', 'day').toISOString().substr(0, 10)
    }
  },

  methods: {
    setDateRange() {
      this.$store.commit('analyticsRange/statsRange', this.localRange)
      this.$emit('click')
    }
  },

  beforeDestroy() {
    this.$store.commit('analyticsRange/resetRange')
  },

}
</script>

<style scoped>

</style>