<template>
  <v-btn
      :loading="isExporting"
      :disabled="isLoading"
      color="blue-grey"
      class="ma-2 white--text"
      small
      @click="exportReport"
  >
    Download
    <v-icon
        right
        dark
    >
      mdi-cloud-download
    </v-icon>
  </v-btn>
</template>

<script>
  import apiClient from "@/plugins/apiClient";
  const querystring = require('querystring');

export default {
  name: "ReportDownload",

  data() {
    return {
      isExporting: false,
    }
  },

  props: {
    type: {
      type: String,
      required: true
    },
    statsRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean
    }
  },

  methods: {
    exportReport() {
      this.isExporting = true

      apiClient.get(this.url + '?' + querystring.stringify(this.statsRange), {responseType: 'arraybuffer'})
      // apiClient.get(this.url + '?start=' + this.statsRange.start + '&end=' + this.statsRange.end + '&country=' + this.statsRange.country, {responseType: 'arraybuffer'})
          .then(response => {
            this.downloadFile(response)
          }, response => {
            console.warn('error from download_contract')
            console.log(response)
            this.isExporting = false
            // Manage errors
          })
    },
    downloadFile(response) {
      console.log('response ', response)
      console.log('response.headers ', response.headers)
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      let country = (this.statsRange.country) ? this.statsRange.country + '_' : '';
      let filename = this.type + '_' + country + this.statsRange.start + '_' + this.statsRange.end + '.xlsx'
      let newBlob = new Blob([response.data], {type: response.headers["content-type"]})

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)
        return
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob)
      let link = document.createElement('a')
      link.href = data
      link.download = filename
      link.click()
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
      }, 100)
      this.isExporting = false
    },
  }
}
</script>

<style scoped>

</style>