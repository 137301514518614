<template>


    <v-card flat
            outlined
            class="mt-5"
    >
      <v-card-text>

        <overlay-loader
            :is-loading="isLoading"
        ></overlay-loader>

        <barchart v-if="chartdata" :chart-data="chartdata" :options="options"></barchart>

      </v-card-text>
    </v-card>

</template>

<script>

import barchart from "@/components/analytics/charts/barchart";

export default {
  name: "BarChartGraph",
  components: {barchart},
  props: {
    chartdata: {
      required: true
    },
    title: String,
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
}
</script>

<style scoped>

</style>