<template>
  <v-card flat class="mt-5">

    <overlay-loader
        :is-loading="isLoading"
    ></overlay-loader>

    <v-card flat
            outlined
            class="mt-5 hidden-sm-and-down"
    >
      <v-card-text>
        <barchart v-if="chartdata" :chart-data="chartdata" :options="options"></barchart>
      </v-card-text>
    </v-card>

    <box-totals class="mt-5" v-if="chartdata.totals" :totals="chartdata.totals"></box-totals>

  </v-card>
</template>

<script>

import barchart from "@/components/analytics/charts/barchart";
import BoxTotals from "@/components/analytics/charts/BoxTotals";

export default {
  name: "BarChartSingle",
  components: {barchart, BoxTotals},
  props: {
    chartdata: {
      required: true
    },
    title: String,
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
}
</script>

<style scoped>

</style>