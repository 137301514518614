<template>
  <v-sheet class="pa-5">

    <range-picker @click="getStats"
                  :is-loading="isLoading"
    ></range-picker>

    <v-card>
      <v-card-title class="title d-flex justify-space-between">
        <div v-html="$t('analytics.receipts.total_title', {start: $parseDate(statsRange.start), end: $parseDate(statsRange.end), country: statsRange.country})"></div>
        <report-download type="receipts"
                         url="analytics/report/receipts"
                         :is-loading="isLoading"
                         :stats-range="statsRange"
        ></report-download>
      </v-card-title>
    </v-card>

    <v-card flat
            outlined
            class="mt-5"
            v-if="statsData.total_receipts > 0"
    >
      <v-card-text>
        <bar-chart-graph v-if="statsData"
                   :chartdata="statsData"
                   :is-loading="isLoading"
        ></bar-chart-graph>
      </v-card-text>
    </v-card>

    <v-card color="mt-5">

          <v-card-text>

            <p>
              <strong class="text-uppercase">{{$t('analytics.receipts.total_amount')}}</strong>: <span class="text--primary font-weight-bold">{{$n(statsData.total_amount, 'currency', statsData.country)}}</span>
            </p>

            <p>
              <strong class="text-uppercase">{{$t('analytics.receipts.amount_avg')}}</strong>: <span class="text--primary font-weight-bold">{{$n(statsData.amount_avg, 'currency', statsData.country)}}</span>
            </p>

            <p>
              <strong class="text-uppercase">{{$t('analytics.receipts.total_receipts')}}</strong>: <span class="text--primary font-weight-bold">{{$n(statsData.total_receipts)}}</span>
            </p>

            <p>
              <strong class="text-uppercase">{{$t('analytics.receipts.unique_users')}}</strong>: <span class="text--primary font-weight-bold">{{$n(statsData.unique_users)}}</span>
            </p>

            <p>
              <strong class="text-uppercase">{{$t('analytics.receipts.users_receipts_avg')}}</strong>: <span class="text--primary font-weight-bold">{{$n(statsData.users_receipts_avg)}}</span>
            </p>

          </v-card-text>
    </v-card>


  </v-sheet>
</template>

<script>

import RangePicker from "@/components/analytics/partials/RangePicker";
import BarChartGraph from "@/components/analytics/charts/BarChartGraph";
import ReportDownload from "@/views/analytics/_partials/ReportDownload";
import {mapGetters} from "vuex";

export default {
  name: "Receipts",
  components: {RangePicker, BarChartGraph, ReportDownload},

  mounted() {
      this.getStats()
  },

  computed: {
    ...mapGetters({
      isLoading: 'analyticsReceipt/loadingState',
      statsData: 'analyticsReceipt/getStatsData',
      statsRange: 'analyticsRange/getStatsRange'
    }),
  },

  methods: {
    getStats() {
      this.$store.dispatch('analyticsReceipt/fetchStats')
    },
  },
}
</script>

<style scoped>

</style>