<template>
  <v-card flat outlined class="mt-5">
    <h4 class="title pa-4">{{ title }}</h4>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col md="3" sm="12">
          <box-totals :totals="chartdata.totals"></box-totals>
        </v-col>
        <v-col md="9" sm="12">
          <overlay-loader
              :is-loading="isLoading"
          ></overlay-loader>
          <barchart v-if="chartdata" :chart-data="chartdata" :options="options"></barchart>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import barchart from "@/components/analytics/charts/barchart";
import BoxTotals from "@/components/analytics/charts/BoxTotals";

export default {
  name: "BarChart",
  components: {barchart, BoxTotals},
  props: {
    chartdata: {
      required: true
    },
    title: String,
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
}
</script>

<style scoped>

</style>