<template>
  <v-sheet class="pa-5">

    <range-picker @click="getStats"
                  :is-loading="isLoading"
    ></range-picker>

    <v-card>
      <v-card-title class="title d-flex justify-space-between">
        <div v-html="$t('analytics.missions.total_title', {start: $parseDate(statsRange.start), end: $parseDate(statsRange.end), country: statsRange.country})"></div>
        <report-download type="missions"
                         url="analytics/report/missions"
                         :is-loading="isLoading"
                         :stats-range="statsRange"
        ></report-download>
      </v-card-title>
    </v-card>

    <bar-chart-single v-if="statsData"
               title="Device"
               :chartdata="statsData"
               :is-loading="isLoading"
    ></bar-chart-single>

  </v-sheet>
</template>

<script>

import BarChartSingle from "@/components/analytics/charts/BarChartSingle";
import RangePicker from "@/components/analytics/partials/RangePicker";
import ReportDownload from "@/views/analytics/_partials/ReportDownload";
import {mapGetters} from "vuex";

export default {
  name: "Missions",
  components: {RangePicker, BarChartSingle, ReportDownload},

  mounted() {
      this.getStats()
  },

  computed: {
    ...mapGetters({
      isLoading: 'analyticsMission/loadingState',
      statsData: 'analyticsMission/getStatsData',
      statsRange: 'analyticsRange/getStatsRange'
    }),
  },

  methods: {
    getStats() {
      this.$store.dispatch('analyticsMission/fetchStats')
    },
  },
}
</script>

<style scoped>

</style>