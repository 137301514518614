<template>
  <v-sheet class="pa-5">

    <range-picker @click="getStats"
                  :is-loading="isLoading"
    ></range-picker>

    <v-card>
      <v-card-title class="title d-flex justify-space-between">
        <div v-html="$t('analytics.users.total_title', {start: $parseDate(statsRange.start), end: $parseDate(statsRange.end), country: statsRange.country})">
          {{ $t('analytics.users.title') }} | {{ $parseDate(statsRange.start) }} - {{ $parseDate(statsRange.end) }}
        </div>

        <report-download type="users"
                         url="analytics/report/users"
                         :is-loading="isLoading"
                         :stats-range="statsRange"
        ></report-download>

      </v-card-title>
      <v-card-text>
        <strong>{{ $t('analytics.users.total_users') }}</strong>: {{ statsData.total }}
      </v-card-text>
    </v-card>

    <bar-chart v-if="statsData && statsData.gender"
               title="Sesso"
               :chartdata="statsData.gender"
               :is-loading="isLoading"
    ></bar-chart>

    <bar-chart v-if="statsData && statsData.device"
               title="Device"
               :chartdata="statsData.device"
               :is-loading="isLoading" 
    ></bar-chart>

  </v-sheet>
</template>

<script>

import BarChart from "@/components/analytics/charts/BarChart.vue";
import RangePicker from "@/components/analytics/partials/RangePicker";
import ReportDownload from "@/views/analytics/_partials/ReportDownload";
import {mapGetters} from "vuex";

export default {
  name: "Users",

  components: {RangePicker, BarChart, ReportDownload},

  mounted() {
      this.getStats()
  },

  computed: {
    ...mapGetters({
      isLoading: 'analyticsUser/loadingState',
      statsData: 'analyticsUser/getStatsData',
      totalUsers: 'analyticsUser/getStatsData',
      statsRange: 'analyticsRange/getStatsRange'
    })
  },

  methods: {
    getStats() {
      this.$store.dispatch('analyticsUser/fetchUserData', 'total')
      this.$store.dispatch('analyticsUser/fetchUserData', 'gender')
      this.$store.dispatch('analyticsUser/fetchUserData', 'device')
      // this.$store.dispatch('analytics/fetchUserData', 'newsletter')
    },
  },
}
</script>

<style scoped>

</style>