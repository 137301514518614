<template>
  <v-card flat outlined>

    <v-card-title class="title">
      Totali
    </v-card-title>

    <v-card-text>

      <p v-for="(total, kind) in totals" :key="kind">
        <strong class="text-uppercase">{{kind}}</strong>: <span class="text--primary font-weight-bold">{{total}}</span>
      </p>

    </v-card-text>
  </v-card>
</template>

<script>
export default {
name: "BoxTotals",

  props: {
  totals: {
    type: Object,
    required: true
  }
  }
}
</script>

<style scoped>

</style>